import { useRef, useState } from "react";
import { useEffect } from "react";
import {
  combtrain,
  combtrain1,
  combtrain2,
  combtrain3,
  trouble,
  trouble1,
  trouble2,
  trouble3,
  ncfit,
  ncfit1,
  ncfit2,
  ncfit3,
  grit,
  grit1,
  grit2,
  grit3,
  zym,
  zym1,
  zym2,
  zym3,
  build,
  build1,
  build2,
  build3,
  prefusion,
  prefusion1,
  prefusion2,
  prefusion4,
  coach,
  coach1,
  coach3,
  coach4,
  willo,
  willo1,
  willo2,
  willo3,
  trailes,
  trailes1,
  trailes3,
  trailes2,
  buzz,
  buzz1,
  buzz2,
  buzz3,
} from "../assets";
import AOS from "aos";
import "aos/dist/aos.css";
import { useNavigate } from "react-router-dom";
import { Headers } from "../component/header";

const Portfolio = () => {
  const navigate = useNavigate();

  useEffect(() => {
    setTimeout(() => {
      AOS.init();
    }, 500);
  }, []);
  return (
    <>
      <div className="">
        <div className="page">
          <div className="container">
            <div className="row">
              <div className="col-md-5">
                {" "}
                <div
                  data-aos="zoom-in"
                  data-aos-duration="1500"
                  className="w-set"
                >
                  <h1 className="">COMPTRAIN</h1>
                  <p>
                    {" "}
                    Are you ready to take your fitness routine to the next
                    level? Look no further than Comptrain, the ultimate
                    companion for your fitness journey. Whether you're a
                    beginner or a seasoned fitness enthusiast, Comptrain is
                    designed to empower you, motivate you, and help you achieve
                    your fitness goals like never before.
                  </p>
                  <span className="">Tech Stack: React Native</span>
                </div>
              </div>
              <div className="col-md-7">
                <div className="row mt-5">
                  <div className="col-md-6 col-6">
                    <div
                      className="text-center"
                      data-aos="flip-left"
                      data-aos-easing="ease-out-cubic"
                      data-aos-duration="1500"
                    >
                      <img src={combtrain} className="combtrain1 flip-right" />
                    </div>
                  </div>
                  <div className="col-md-6  col-6">
                    <div
                      className="text-center"
                      data-aos="flip-right"
                      data-aos-easing="ease-out-cubic"
                      data-aos-duration="1500"
                    >
                      <img src={combtrain1} className="combtrain1 mt-5" />
                    </div>
                  </div>
                </div>
                <div className="row mt-5 mb-5">
                  <div className="col-md-6  col-6">
                    <div
                      className="text-center"
                      data-aos="flip-left"
                      data-aos-easing="ease-out-cubic"
                      data-aos-duration="1500"
                    >
                      <img src={combtrain2} className="combtrain1 flip-right" />
                    </div>
                  </div>
                  <div className="col-md-6  col-6">
                    <div
                      className="text-center"
                      data-aos="flip-right"
                      data-aos-easing="ease-out-cubic"
                      data-aos-duration="1500"
                    >
                      <img
                        src={combtrain3}
                        className="combtrain1 flip-left mt-5"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="page">
          <div className="container">
            <div className="row">
              <div className="col-md-5">
                {" "}
                <div
                  data-aos="zoom-in"
                  data-aos-duration="1500"
                  className="w-set"
                >
                  <h1 className="">WILLO</h1>
                  <p className="">
                    {" "}
                    The Willo brushing app is likely associated with the Willo
                    smart toothbrush system. Willo is an innovative dental care
                    product designed to improve brushing efficiency and oral
                    hygiene. The system usually includes a smart toothbrush and
                    an accompanying app that provides feedback and tracks
                    brushing habits.
                  </p>
                  <span className="">Tech Stack: Android and iOS</span>
                </div>
              </div>
              <div className="col-md-7">
                <div className="row mt-5">
                  <div className="col-md-6 col-6">
                    <div
                      className="text-center"
                      data-aos="flip-left"
                      data-aos-easing="ease-out-cubic"
                      data-aos-duration="1500"
                    >
                      <img src={willo} className="combtrain1 flip-right" />
                    </div>
                  </div>
                  <div className="col-md-6  col-6">
                    <div
                      className="text-center"
                      data-aos="flip-right"
                      data-aos-easing="ease-out-cubic"
                      data-aos-duration="1500"
                    >
                      <img src={willo1} className="combtrain1 mt-5" />
                    </div>
                  </div>
                </div>
                <div className="row mt-5 mb-5">
                  <div className="col-md-6  col-6">
                    <div
                      className="text-center"
                      data-aos="flip-left"
                      data-aos-easing="ease-out-cubic"
                      data-aos-duration="1500"
                    >
                      <img src={willo2} className="combtrain1 flip-right" />
                    </div>
                  </div>
                  <div className="col-md-6  col-6">
                    <div
                      className="text-center"
                      data-aos="flip-right"
                      data-aos-easing="ease-out-cubic"
                      data-aos-duration="1500"
                    >
                      <img src={willo3} className="combtrain1 flip-left mt-5" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="page">
          <div className="container">
            <div className="row">
              <div className="col-md-5">
                {" "}
                <div
                  data-aos="zoom-in"
                  data-aos-duration="1500"
                  className="w-set"
                >
                  <h1 className="">BUZZ</h1>
                  <p className="">
                    {" "}
                    Buzz is the most fun way to meet people and make new
                    friends. Think of it as the best party spot which the whole
                    world can enter! You'll get to find peeps with your vibe,
                    nearby or anywhere around the world. Buzz helps you match
                    through interests in 6 different categories which are;
                    Music, Movies, Food, Gaming, Sports, and Travel.
                  </p>
                  <span className="">Tech Stack: Flutter</span>
                </div>
              </div>
              <div className="col-md-7">
                <div className="row mt-5">
                  <div className="col-md-6 col-6">
                    <div
                      className="text-center"
                      data-aos="flip-left"
                      data-aos-easing="ease-out-cubic"
                      data-aos-duration="1500"
                    >
                      <img src={buzz} className="combtrain1 flip-right" />
                    </div>
                  </div>
                  <div className="col-md-6  col-6">
                    <div
                      className="text-center"
                      data-aos="flip-right"
                      data-aos-easing="ease-out-cubic"
                      data-aos-duration="1500"
                    >
                      <img src={buzz1} className="combtrain1 mt-5" />
                    </div>
                  </div>
                </div>
                <div className="row mt-5 mb-5">
                  <div className="col-md-6  col-6">
                    <div
                      className="text-center"
                      data-aos="flip-left"
                      data-aos-easing="ease-out-cubic"
                      data-aos-duration="1500"
                    >
                      <img src={buzz2} className="combtrain1 flip-right" />
                    </div>
                  </div>
                  <div className="col-md-6  col-6">
                    <div
                      className="text-center"
                      data-aos="flip-right"
                      data-aos-easing="ease-out-cubic"
                      data-aos-duration="1500"
                    >
                      <img src={buzz3} className="combtrain1 flip-left mt-5" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="page">
          <div className="container">
            <div className="row">
              <div className="col-md-5">
                {" "}
                <div
                  data-aos="zoom-in"
                  data-aos-duration="1500"
                  className="w-set"
                >
                  <h1 className="">NCFIT</h1>
                  <p>
                    {" "}
                    Welcome to the next generation of fitness training with the
                    NCFIT Workout App. Whether you're an athlete, a fitness
                    enthusiast, or just starting your fitness journey, our app
                    is designed to provide you with the tools, guidance, and
                    motivation you need to reach your goals and unlock your full
                    potential.
                  </p>
                  <span className="">Tech Stack: Android and iOS</span>
                </div>
              </div>
              <div className="col-md-7">
                <div className="row mt-5">
                  <div className="col-md-6 col-6">
                    <div
                      className="text-center"
                      data-aos="flip-left"
                      data-aos-duration="1500"
                      data-aos-easing="ease-out-cubic"
                    >
                      <img src={ncfit} className="combtrain1 flip-right" />
                    </div>
                  </div>
                  <div className="col-md-6  col-6">
                    <div
                      className="text-center"
                      data-aos="flip-right"
                      data-aos-easing="ease-out-cubic"
                      data-aos-duration="1500"
                    >
                      <img src={ncfit1} className="combtrain1 mt-5" />
                    </div>
                  </div>
                </div>
                <div className="row mt-5 mb-5">
                  <div className="col-md-6  col-6">
                    <div
                      className="text-center"
                      data-aos="flip-left"
                      data-aos-easing="ease-out-cubic"
                      data-aos-duration="1500"
                    >
                      <img src={ncfit2} className="combtrain1 flip-right" />
                    </div>
                  </div>
                  <div className="col-md-6  col-6">
                    <div
                      className="text-center"
                      data-aos="flip-right"
                      data-aos-easing="ease-out-cubic"
                      data-aos-duration="1500"
                    >
                      <img src={ncfit3} className="combtrain1 flip-left mt-5" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="page">
          <div className="container">
            <div className="row">
              <div className="col-md-5">
                {" "}
                <div
                  data-aos="zoom-in"
                  data-aos-duration="1500"
                  className="w-set"
                >
                  <h1 className="text-dark">LAUREN</h1>
                  <p className="text-dark">
                    {" "}
                    Welcome to Lauren App, the ultimate fitness companion
                    designed exclusively for girls who are ready to embrace
                    their strength, confidence, and limitless potential. We
                    believe that fitness is not just about physical strength but
                    also about empowering young women to become the best
                    versions of themselves in all aspects of life.
                  </p>
                  <span className="text-dark">Tech Stack: Android and iOS</span>
                </div>
              </div>
              <div className="col-md-7">
                <div className="row mt-5">
                  <div className="col-md-6 col-6">
                    <div
                      className="text-center"
                      data-aos="flip-left"
                      data-aos-easing="ease-out-cubic"
                      data-aos-duration="1500"
                    >
                      <img src={grit} className="combtrain1 flip-right" />
                    </div>
                  </div>
                  <div className="col-md-6  col-6">
                    <div
                      className="text-center"
                      data-aos="flip-right"
                      data-aos-easing="ease-out-cubic"
                      data-aos-duration="1500"
                    >
                      <img src={grit1} className="combtrain1 mt-5" />
                    </div>
                  </div>
                </div>
                <div className="row mt-5 mb-5">
                  <div className="col-md-6  col-6">
                    <div
                      className="text-center"
                      data-aos="flip-left"
                      data-aos-easing="ease-out-cubic"
                      data-aos-duration="1500"
                    >
                      <img src={grit2} className="combtrain1 flip-right" />
                    </div>
                  </div>
                  <div className="col-md-6  col-6">
                    <div
                      className="text-center"
                      data-aos="flip-right"
                      data-aos-easing="ease-out-cubic"
                      data-aos-duration="1500"
                    >
                      <img src={grit3} className="combtrain1 flip-left mt-5" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="page">
          <div className="container">
            <div className="row">
              <div className="col-md-5">
                {" "}
                <div
                  data-aos="zoom-in"
                  data-aos-duration="1500"
                  className="w-set"
                >
                  <h1 className="text-dark">GROWN APP</h1>
                  <p className="text-dark">
                    {" "}
                    Fueling your body with the right nutrition is essential for
                    overall well-being. Grown Strong App provides expert
                    nutrition guidance, helping you make informed choices to
                    support your fitness goals. Explore a wealth of healthy
                    recipes, meal plans, and nutritional tips that align with
                    your dietary preferences and requirements. Whether you're
                    aiming to lose weight, build muscle, or simply maintain a
                    balanced lifestyle, Grown Strong has you covered.
                  </p>
                  <span className="text-dark">Tech Stack: iOS</span>
                </div>
              </div>
              <div className="col-md-7">
                <div className="row mt-5">
                  <div className="col-md-6 col-6">
                    <div
                      className="text-center"
                      data-aos="flip-left"
                      data-aos-easing="ease-out-cubic"
                      data-aos-duration="1500"
                    >
                      <img src={zym} className="combtrain1 flip-right" />
                    </div>
                  </div>
                  <div className="col-md-6  col-6">
                    <div
                      className="text-center"
                      data-aos="flip-right"
                      data-aos-easing="ease-out-cubic"
                      data-aos-duration="1500"
                    >
                      <img src={zym1} className="combtrain1 mt-5" />
                    </div>
                  </div>
                </div>
                <div className="row mt-5 mb-5">
                  <div className="col-md-6  col-6">
                    <div
                      className="text-center"
                      data-aos="flip-left"
                      data-aos-easing="ease-out-cubic"
                      data-aos-duration="1500"
                    >
                      <img src={zym2} className="combtrain1 flip-right" />
                    </div>
                  </div>
                  <div className="col-md-6  col-6">
                    <div
                      className="text-center"
                      data-aos="flip-right"
                      data-aos-easing="ease-out-cubic"
                      data-aos-duration="1500"
                    >
                      <img src={zym3} className="combtrain1 flip-left mt-5" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="page">
          <div className="container">
            <div className="row">
              <div className="col-md-5">
                {" "}
                <div
                  data-aos="zoom-in"
                  data-aos-duration="1500"
                  className="w-set"
                >
                  <h1 className="text-dark">THE READY STATE</h1>
                  <p className="text-dark">
                    {" "}
                    Welcome to The Ready State App, your comprehensive solution
                    for optimizing your movement, enhancing performance, and
                    taking care of your body's needs. With a focus on mobility,
                    flexibility, and targeted workouts for all body parts, this
                    app is designed to help you achieve a state of readiness and
                    peak physical condition.
                  </p>
                  <span className="text-dark">Tech Stack: Android and iOS</span>
                </div>
              </div>
              <div className="col-md-7">
                <div className="row mt-5">
                  <div className="col-md-6 col-6">
                    <div
                      className="text-center"
                      data-aos="flip-left"
                      data-aos-easing="ease-out-cubic"
                      data-aos-duration="1500"
                    >
                      <img src={build} className="combtrain1 flip-right" />
                    </div>
                  </div>
                  <div className="col-md-6  col-6">
                    <div
                      className="text-center"
                      data-aos="flip-right"
                      data-aos-easing="ease-out-cubic"
                      data-aos-duration="1500"
                    >
                      <img src={build1} className="combtrain1 mt-5" />
                    </div>
                  </div>
                </div>
                <div className="row mt-5 mb-5">
                  <div className="col-md-6  col-6">
                    <div
                      className="text-center"
                      data-aos="flip-left"
                      data-aos-easing="ease-out-cubic"
                      data-aos-duration="1500"
                    >
                      <img src={build2} className="combtrain1 flip-right" />
                    </div>
                  </div>
                  <div className="col-md-6  col-6">
                    <div
                      className="text-center"
                      data-aos="flip-right"
                      data-aos-easing="ease-out-cubic"
                      data-aos-duration="1500"
                    >
                      <img src={build3} className="combtrain1 flip-left mt-5" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="page">
          <div className="container">
            <div className="row">
              <div className="col-md-5">
                {" "}
                <div
                  data-aos="zoom-in"
                  data-aos-duration="1500"
                  className="w-set"
                >
                  <h1 className="">PREFUSION</h1>
                  <p className="">
                    {" "}
                    Prefusion is an innovative mobile application designed to
                    enhance your social networking experience. It leverages
                    advanced technologies and cutting-edge features to provide a
                    seamless and interactive platform for connecting with
                    friends, family, and like-minded individuals.
                  </p>
                  <span className="">Tech Stack: React Native</span>
                </div>
              </div>
              <div className="col-md-7">
                <div className="row mt-5">
                  <div className="col-md-6 col-6">
                    <div
                      className="text-center"
                      data-aos="flip-left"
                      data-aos-easing="ease-out-cubic"
                      data-aos-duration="1500"
                    >
                      <img src={prefusion} className="combtrain1 flip-right" />
                    </div>
                  </div>
                  <div className="col-md-6  col-6">
                    <div
                      className="text-center"
                      data-aos="flip-right"
                      data-aos-easing="ease-out-cubic"
                      data-aos-duration="1500"
                    >
                      <img src={prefusion1} className="combtrain1 mt-5" />
                    </div>
                  </div>
                </div>
                <div className="row mt-5 mb-5">
                  <div className="col-md-6  col-6">
                    <div
                      className="text-center"
                      data-aos="flip-left"
                      data-aos-easing="ease-out-cubic"
                      data-aos-duration="1500"
                    >
                      <img src={prefusion2} className="combtrain1 flip-right" />
                    </div>
                  </div>
                  <div className="col-md-6  col-6">
                    <div
                      className="text-center"
                      data-aos="flip-right"
                      data-aos-easing="ease-out-cubic"
                      data-aos-duration="1500"
                    >
                      <img
                        src={prefusion4}
                        className="combtrain1 flip-left mt-5"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="page">
          <div className="container">
            <div className="row">
              <div className="col-md-5">
                {" "}
                <div
                  data-aos="zoom-in"
                  data-aos-duration="1500"
                  className="w-set"
                >
                  <h1 className="">UNSTOPPABLE</h1>
                  <p className="">
                    {" "}
                    Goal Setting and Tracking: Set clear and meaningful goals,
                    whether they are related to your career, health,
                    relationships, personal development, or any other area of
                    your life. Track your progress, celebrate milestones, and
                    stay motivated with visual indicators of your achievements.
                  </p>
                  <span className="">Tech Stack: React Native</span>
                </div>
              </div>
              <div className="col-md-7">
                <div className="row mt-5">
                  <div className="col-md-6 col-6">
                    <div
                      className="text-center"
                      data-aos="flip-left"
                      data-aos-easing="ease-out-cubic"
                      data-aos-duration="1500"
                    >
                      <img src={coach} className="combtrain1 flip-right" />
                    </div>
                  </div>
                  <div className="col-md-6  col-6">
                    <div
                      className="text-center"
                      data-aos="flip-right"
                      data-aos-easing="ease-out-cubic"
                      data-aos-duration="1500"
                    >
                      <img src={coach1} className="combtrain1 mt-5" />
                    </div>
                  </div>
                </div>
                <div className="row mt-5 mb-5">
                  <div className="col-md-6  col-6">
                    <div
                      className="text-center"
                      data-aos="flip-left"
                      data-aos-easing="ease-out-cubic"
                      data-aos-duration="1500"
                    >
                      <img src={coach3} className="combtrain1 flip-right" />
                    </div>
                  </div>
                  <div className="col-md-6  col-6">
                    <div
                      className="text-center"
                      data-aos="flip-right"
                      data-aos-easing="ease-out-cubic"
                      data-aos-duration="1500"
                    >
                      <img src={coach4} className="combtrain1 flip-left mt-5" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="page">
          <div className="container">
            <div className="row">
              <div className="col-md-5">
                {" "}
                <div
                  data-aos="zoom-in"
                  data-aos-duration="1500"
                  className="w-set"
                >
                  <h1 className="">RINNAI</h1>
                  <p>
                    {" "}
                    Experience a new level of convenience and control with the
                    revolutionary Rinnai App. Designed to seamlessly connect
                    with your Rinnai water heater, this innovative app puts the
                    power in your hands, allowing you to effortlessly manage and
                    customize your water heating preferences like never before.
                  </p>
                  <span className="">Tech Stack: Android and iOS</span>
                </div>
              </div>
              <div className="col-md-7">
                <div className="row mt-5">
                  <div className="col-md-6 col-6">
                    <div
                      className="text-center"
                      data-aos="flip-left"
                      data-aos-easing="ease-out-cubic"
                      data-aos-duration="1500"
                    >
                      <img
                        src={trouble}
                        className="combtrain1 flip-left mt-5"
                      />
                    </div>
                  </div>
                  <div className="col-md-6  col-6">
                    <div
                      className="text-center"
                      data-aos="flip-right"
                      data-aos-easing="ease-out-cubic"
                      data-aos-duration="1500"
                    >
                      <img
                        src={trouble1}
                        className="combtrain1 flip-left mt-5"
                      />
                    </div>
                  </div>
                </div>
                <div className="row mt-5 mb-5">
                  <div className="col-md-6  col-6">
                    <div
                      className="text-center"
                      data-aos="flip-left"
                      data-aos-easing="ease-out-cubic"
                      data-aos-duration="1500"
                    >
                      <img
                        src={trouble2}
                        className="combtrain1 flip-left mt-5"
                      />
                    </div>
                  </div>
                  <div className="col-md-6  col-6">
                    <div
                      className="text-center"
                      data-aos="flip-right"
                      data-aos-easing="ease-out-cubic"
                      data-aos-duration="1500"
                    >
                      {" "}
                      <img
                        src={trouble3}
                        className="combtrain1 flip-left mt-5"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="page">
          <div className="container">
            <div className="row">
              <div className="col-md-5">
                {" "}
                <div
                  data-aos="zoom-in"
                  data-aos-duration="1500"
                  className="w-set"
                >
                  <h1 className="">30 SECOND TRAILER</h1>
                  <p className="">
                    {" "}
                    your go-to app for recorded meetings and conversations.
                    Perfect for business meetings, interviews, or casual chats,
                    This platform ensures that every discussion is captured in
                    full from start to finish. By joining a chat, you
                    automatically consent to the recording, making it seamless
                    to review and share important conversations. With secure
                    storage and easy playback, 30 Seconds Trailer is ideal for
                    professionals and individuals who never want to miss a
                    moment. Experience the reliability and convenience of a
                    recorded meeting app designed for today's connected world.
                  </p>
                  <span className="">Tech Stack: React Native</span>
                </div>
              </div>
              <div className="col-md-7">
                <div className="row mt-5">
                  <div className="col-md-6 col-6">
                    <div
                      className="text-center"
                      data-aos="flip-left"
                      data-aos-easing="ease-out-cubic"
                      data-aos-duration="1500"
                    >
                      <img src={trailes} className="combtrain1 flip-right" />
                    </div>
                  </div>
                  <div className="col-md-6  col-6">
                    <div
                      className="text-center"
                      data-aos="flip-right"
                      data-aos-easing="ease-out-cubic"
                      data-aos-duration="1500"
                    >
                      <img src={trailes1} className="combtrain1 mt-5" />
                    </div>
                  </div>
                </div>
                <div className="row mt-5 mb-5">
                  <div className="col-md-6  col-6">
                    <div
                      className="text-center"
                      data-aos="flip-left"
                      data-aos-easing="ease-out-cubic"
                      data-aos-duration="1500"
                    >
                      <img src={trailes3} className="combtrain1 flip-right" />
                    </div>
                  </div>
                  <div className="col-md-6  col-6">
                    <div
                      className="text-center"
                      data-aos="flip-right"
                      data-aos-easing="ease-out-cubic"
                      data-aos-duration="1500"
                    >
                      <img
                        src={trailes2}
                        className="combtrain1 flip-left mt-5"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Portfolio;
