import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Portfolio from "../views/Portfolio";
import Web from "../views/Web";

function Navigator() {
  return (
  <Router>
    <Routes>
      <Route path="*" element={<Portfolio />} />
    </Routes>
  </Router>
  )
}

export default Navigator;
