import { useRef, useState } from "react";
import { useEffect } from "react";
import {
  biopac,
  dc,
  NCFIT,
  producelocatore,
  shopify,
  shopify1,
} from "../assets";
import AOS from "aos";
import "aos/dist/aos.css";
import { useNavigate } from "react-router-dom";
import { Headers } from "../component/header";

const Web = () => {
  const navigate = useNavigate();

  useEffect(() => {
    setTimeout(() => {
      AOS.init();
    }, 500);
  }, []);
  return (
    <>
      <div className="">
      <div className="page-web">
          <div className="container">
            <div className="row">
              <div className="col-md-5">
                {" "}
                <div
                  data-aos="zoom-in"
                  data-aos-duration="1500"
                  className="w-sets"
                >
                  <h1 className="">BIOPAC</h1>tech stack 
                  <p className="text-white">
                    {" "}
                    Bio Pac is a comprehensive web-based SAAS platform designed
                    for researchers to manage their studies and data
                    efficiently. It provides features such as account management
                    for different user roles (Admins, Managers, Researchers),
                    Two-Factor Authentication (2FA) for enhanced security, and
                    robust file management capabilities supporting various
                    formats. The platform allows users to manage research
                    studies, user groups, and metadata, track data storage and
                    costs, and handle inventory and shipping of physical
                    devices, ensuring a streamlined and secure research
                    environment.
                  </p>
                </div>
              </div>
              <div className="col-md-7">
                <div className="mt-5">
                  <div className="col-md-12 col-12">
                    <div className="text-center">
                      <img
                        src={biopac}
                        className="biopac-img w-100"
                        data-aos="flip-left"
                        data-aos-easing="ease-out-cubic"
                        data-aos-duration="1500"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="page-web">
          <div className="container">
            <div className="row">
              <div className="col-md-5">
                {" "}
                <div
                  data-aos="zoom-in"
                  data-aos-duration="1500"
                  className="w-sets"
                >
                  <h1 className="text-dark">DC DMV</h1>
                  <p className="text-dark">
                    {" "}
                    The driver's educational website transforms the traditional
                    driver's manual into 13 easy-to-understand chapters, each
                    designed to make learning traffic rules simple and engaging.
                    Each lesson is followed by multiple-choice quizzes to
                    reinforce your understanding and ensure you're retaining the
                    key information. Choose a lesson, learn at your own pace,
                    and take the quizzes to become a confident and knowledgeable
                    driver. Our structured approach ensures that you not only
                    prepare thoroughly for your driving test but also build a
                    solid foundation for safe driving practices.
                  </p>
                </div>
              </div>
              <div className="col-md-7">
                <div className="mt-5">
                  <div className="col-md-12 col-12">
                    <div className="text-center">
                      <img
                        src={dc}
                        className="biopac-img w-100"
                        data-aos="flip-left"
                        data-aos-easing="ease-out-cubic"
                        data-aos-duration="1500"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="page-web">
          <div className="container">
            <div className="row">
              <div className="col-md-5">
                {" "}
                <div
                  data-aos="zoom-in"
                  data-aos-duration="1500"
                  className="w-sets"
                >
                  <h1 className="text-dark">NCFIT WEB</h1>
                  <p className="text-dark">
                    {" "}
                    The NCFIT Workout App admin panel is a robust and intuitive
                    interface designed to provide administrators with full
                    control over the app’s operations and user experience. With
                    features ranging from user management to detailed analytics,
                    the admin panel ensures that administrators can effectively
                    manage and optimize the app to meet the needs of its users.
                  </p>
                </div>
              </div>
              <div className="col-md-7">
                <div className="mt-5">
                  <div className="col-md-12 col-12">
                    <div className="text-center">
                      <img
                        src={NCFIT}
                        className="biopac-img w-100"
                        data-aos="flip-left"
                        data-aos-easing="ease-out-cubic"
                        data-aos-duration="1500"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="page-web">
          <div className="container">
            <div className="row">
              <div className="col-md-5">
                {" "}
                <div
                  data-aos="zoom-in"
                  data-aos-duration="1500"
                  className="w-sets"
                >
                  <h1 className="">PRODUCE LOCATOR</h1>
                  <p className="">
                    {" "}
                    The Produce Locator website serves as an online platform
                    connecting vendors, consumers, and grocers, offering a
                    seamless shopping experience for all parties involved. It
                    combines the convenience of e-commerce with the variety and
                    freshness of a traditional grocery store. The website is
                    designed with user-friendly interfaces, efficient search
                    functionalities, and secure payment gateways to ensure a
                    smooth transaction process.
                  </p>
                </div>
              </div>
              <div className="col-md-7">
                <div className="mt-5">
                  <div className="col-md-12 col-12">
                    <div className="text-center">
                      <img
                        src={producelocatore}
                        className="biopac-img w-100"
                        data-aos="flip-left"
                        data-aos-easing="ease-out-cubic"
                        data-aos-duration="1500"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="page-web">
          <div className="container">
            <div className="row">
              <div className="col-md-5">
                {" "}
                <div
                  data-aos="zoom-in"
                  data-aos-duration="1500"
                  className="w-sets"
                >
                  <h1 className="">FLEXILIGHTPRO</h1>
                  <p className="">
                    {" "}
                    LEXILIGHTPRO professional lightening system includes dual,
                    flexible arms with a telescopic pole for easy transportation
                    and adjustability. The base is extra sturdy compared to
                    other lamps on the market.
                  </p>
                </div>
              </div>
              <div className="col-md-7">
                <div className="mt-5">
                  <div className="col-md-12 col-12">
                    <div className="text-center">
                      <img
                        src={shopify}
                        className="biopac-img w-100"
                        data-aos="flip-left"
                        data-aos-easing="ease-out-cubic"
                        data-aos-duration="1500"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="page-web">
          <div className="container">
            <div className="row">
              <div className="col-md-5">
                {" "}
                <div
                  data-aos="zoom-in"
                  data-aos-duration="1500"
                  className="w-sets"
                >
                  <h1 className="">BEAUTOLOGIE</h1>
                  <p className="">
                    {" "}
                    Beautologie is the widely known as the top plastic surgery center in Bakersfield, Fresno, Stockton and Newport Beach California. Cosmetic Surgery procedures offered at Beautologie include Breast Augmentation, Tummy Tuck, Liposuction and Mommy Makeovers. We also have a giant Medical Aesthetics practice performing Botox™, Restylane, Fillers, Injectables, Lasers and more.
                  </p>
                </div>
              </div>
              <div className="col-md-7">
                <div className="mt-5">
                  <div className="col-md-12 col-12">
                    <div className="text-center">
                      <img
                        src={shopify1}
                        className="biopac-img w-100"
                        data-aos="flip-left"
                        data-aos-easing="ease-out-cubic"
                        data-aos-duration="1500"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Web;
