import { Link } from "react-router-dom";

export function Headers()  {
    return (<nav class="navbar navbar-expand-sm bg-black navbar-light static-data">
        <ul class="navbar-nav">
          <li class="nav-item active-show">
            <Link to="/" class="nav-link">Mobile</Link>
          </li>
          <li class="nav-item">
            <Link to="/web" class="nav-link">Web</Link>
          </li>
        </ul>
      </nav>)
      }