import Navigator from "./app/navigation/router";
import './App.css';

function App() {
  return (
    <Navigator />
  );
}

export default App;
